import { useSnackbar } from 'notistack';
// @mui
import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function ShortUrlCopy() {
  const { user } = useAuth();

  const { enqueueSnackbar } = useSnackbar();

  const { copy } = useCopyToClipboard();

  const CopyText = `www.impaac.org?ref=${user?.ref}`;

  const onCopy = (shortUrl) => {
    if (shortUrl) {
      copy(shortUrl);
      enqueueSnackbar('Copied Reference URL!');
    }
  };

  return (
    <div>
      <Tooltip title="Copy Reference URL">
        <Button variant="contained" onClick={() => onCopy(CopyText)}>
          Referral URL <Iconify icon="eva:copy-fill" width={30} height={30} sx={{ ml: 1 }} />
        </Button>
      </Tooltip>
    </div>
  );
}
