import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendPasswordResetEmail,
} from 'firebase/auth';
//
import { FIREBASE_API } from '../config';
import { getAuserHelper, updateUser } from '../helper/user';
import { isAutheticated, signup } from '../helper/auth';
import { createOrganisationHelper, getNgoHelper, updateOrganisationHelper } from '../helper/ngo';

// ----------------------------------------------------------------------

const ADMIN_EMAILS = [''];

const firebaseApp = initializeApp(FIREBASE_API);
const AUTH = getAuth(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  refreshUser: () => Promise.resolve(),
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  registerNgo: () => Promise.resolve(),
  updateNgoProfile: () => Promise.resolve(),
  refreshOgo: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState(null);

  const [ngoProfile, setNgoProfile] = useState(null);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const { accessToken, displayName, email, phoneNumber, uid } = user;

          localStorage.setItem('expirationTime', user?.stsTokenManager?.expirationTime.toString());

          const response = await getAuserHelper(user.uid, user.accessToken);

          if (response.error && displayName && email) {
            await signup(accessToken, { name: displayName, email, phonenumber: phoneNumber }).then((data) => {
              if (data.error) {
                console.log(data.error);
              }
              setProfile(data);
            });
          }

          setProfile(response);
          localStorage.setItem('role', response?.role);

          const accessObject = {
            dashboard: response?.admin_access?.admin_dashboard,
            role: response?.admin_access?.role,
            campaign: response?.admin_access?.campaign,
            withdrawal: response?.admin_access?.withdrawal,
            donation: response?.admin_access?.donation,
            donor: response?.admin_access?.donor,
            ngo: response?.admin_access?.ngo,
            user: response?.admin_access?.user,
          };

          const accessObjectString = JSON.stringify(accessObject);

          // Store the stringified object in local storage
          localStorage.setItem('accessObject', accessObjectString);

          const user_role = JSON.stringify(response?.user_role);
          localStorage.setItem('user_role', user_role);

          if (response.organisation_id) {
            const res = await getNgoHelper(response._id, user.accessToken, response.organisation_id);
            setNgoProfile(res);
          }

          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  function refreshAccessToken() {
    onAuthStateChanged(AUTH, async (user) => {
      if (user) {
        const newExpirationTime = user?.stsTokenManager?.expirationTime;
        localStorage.setItem('expirationTime', newExpirationTime.toString());
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: true, user },
        });
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: false, user: null },
        });
      }
    });
  }

  function refreshPage() {
    // Get the current date and time
    const currentTime = new Date();
    const refreshTime = localStorage.getItem('expirationTime');

    // Calculate the time remaining until the refresh time
    const timeUntilRefresh = refreshTime - currentTime;

    if (timeUntilRefresh > 0) {
      setTimeout(() => {
        refreshAccessToken();
      }, timeUntilRefresh);
    }
  }

  refreshPage();

  const refreshUser = async (uid, token) => {
    const response = await getAuserHelper(uid, token);
    setProfile(response);
  };
  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  const register = (email, password, firstName, lastName) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      await signup(res.user.accessToken, { name: firstName, email });
    });

  const resetPassword = (email) => {
    sendPasswordResetEmail(AUTH, email);
  };

  const updateUserProfile = (userId, data) => {
    const { displayName, photoURL } = data;
    updateProfile(AUTH.currentUser, { displayName, photoURL });
  };

  const logout = () => signOut(AUTH);

  const refreshNgo = async (userId, token, ngoId) => {
    const response = await getNgoHelper(userId, token, ngoId);
    setNgoProfile(response);
  };

  const registerNgo = async (userId, token, data) => {
    const { organsationName, organsationPanCard, address, state, areaCode, city, country } = data;
    await createOrganisationHelper(userId, token, {
      organisation_name: organsationName,
      organisation_pan: organsationPanCard,
      address: { street: address, area_code: areaCode, state, city, country },
    });
  };

  const updateNgoProfile = async (userId, token, ngoId, data) => {
    const { organsationName, organsationPanCard, address, state, areaCode, city, country } = data;
    await updateOrganisationHelper(userId, token, ngoId, {
      organisation_name: organsationName,
      organisation_pan: organsationPanCard,
      address: { street: address, area_code: areaCode, state, city, country },
    });
  };
  const generateRecaptcha = (captachId) => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      captachId,
      {
        size: 'invisible',
      },
      AUTH
    );
  };
  const registerWithPhoneNumber = async (data) => {
    const appVerifier = window.recaptchaVerifier;
    const { country, phoneNumber } = data;
    const number = `\n+${country}${phoneNumber}`;

    const result = await signInWithPhoneNumber(AUTH, number, appVerifier);
    if (result.error) {
      return result.error;
    }
  };

  const verifyOtp = async (otp, data) => {
    const { confirmationResult } = window;
    confirmationResult
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          role: profile?.role,
          uid: state?.user?.uid,
          ngoId: profile?.organisation_id,
          id: profile?._id,
          email: state?.user?.email,
          ref: profile?.ref,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.name,
          phoneNumber: state?.user?.phoneNumber || profile?.phonenumber || '',
          country: profile?.address?.country || '',
          address: profile?.address?.street || '',
          state: profile?.address?.state || '',
          city: profile?.address?.city || '',
          areaCode: profile?.address?.area_code || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
          token: state?.user?.accessToken,
          facebook: profile?.social_links?.facebook,
          twitter: profile?.social_links?.twitter,
          linkedin: profile?.social_links?.linkedin,
          instagram: profile?.social_links?.instagram,
          following: profile?.following,
          followers: profile?.followers,
          kycStatus: profile?.kyc_status,
        },
        ngo: {
          name: ngoProfile?.organisation_name || '',
          panCard: ngoProfile?.organisation_pan || '',
          country: ngoProfile?.address?.country || '',
          address: ngoProfile?.address?.street || '',
          state: ngoProfile?.address?.state || '',
          city: ngoProfile?.address?.city || '',
          areaCode: ngoProfile?.address?.area_code || '',
          about: ngoProfile?.about || '',
        },

        refreshUser,
        login,
        register,
        resetPassword,
        updateUserProfile,
        logout,
        registerNgo,
        refreshNgo,
        updateNgoProfile,
        generateRecaptcha,
        registerWithPhoneNumber,
        verifyOtp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
