const { getStorage, uploadBytesResumable, getDownloadURL, ref } = require("firebase/storage");

const storage = getStorage();




export const UploadImage = (file, path) => {
    const storageRef = ref(storage, path);


    const uploadTask = uploadBytesResumable(storageRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            async () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL)
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                // const imgURL = await uploadTask.snapshot.ref.getDownloadURL();
                // resolve(imgURL);
            }
        );
    })
}
