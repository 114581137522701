import { API } from "../config";


// create a NGO
export const createOrganisationHelper = (userId, token, organisation) => fetch(`${API}/api/organisation/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(organisation),
    })
        .then((response) => response.json())
        .catch((err) => console.log(err));

// create a NGO
export const updateOrganisationHelper = (userId, token, ngoId, data) => fetch(`${API}/api/organisation/update/${userId}/${ngoId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => console.log(err));



// create a User To organisation
export const OrganisationLinkToUserHelper = (userId, token, organisation) => fetch(`${API}/api/organisationLinkToUser/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(organisation),
    })
        .then((response) => response.json())
        .catch((err) => console.log(err));



export const getAllNgoHelper = (userId, token) => fetch(`${API}/api/ngo/${userId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then((response) => response.json()).catch((err) => console.log(err));


export const getNgoHelper = (userId, token, ngoId) => fetch(`${API}/api/ngo/one/${userId}/${ngoId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
        },
    }).then((response) => response.json()).catch((err) => console.log(err));
