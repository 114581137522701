import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, styled } from '@mui/material';


import impaaclogo from "../assets/IFLogo.png";

const LogoBox = styled(Box)(({ theme }) => ({
  width: 175,
  height: 50,
  [theme.breakpoints.down('md')]: {
    width: 120,
  },

}))

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {

  const logo = (
    <div>
      <LogoBox sx={{ ...sx }}>
        <img src={impaaclogo} alt="logo" width="150"/>
      </LogoBox>
    </div>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
