import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import ProtectedRoute from '../guards/ProtectedRoute';

// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const storedAccessString = localStorage.getItem('accessObject');
const storedAccess = JSON.parse(storedAccessString);
const getUserRole = localStorage.getItem('user_role');
const userRole = JSON.parse(getUserRole);

export default function Router() {
  const { user } = useAuth();

  return useRoutes([
    {
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app',
          element: (
            <ProtectedRoute isAllowed={!!user && storedAccess?.dashboard === true} redirectTo="/admin/403">
              <GeneralApp />
            </ProtectedRoute>
          ),
        },
        {
          path: 'donationexpense',
          element: (
            <ProtectedRoute isAllowed={!!user && storedAccess?.dashboard === true} redirectTo="/admin/403">
              <DonationExpenseReport />
            </ProtectedRoute>
          ),
        },
        {
          path: 'mydashboard',
          element: (
            <ProtectedRoute isAllowed={!!user && userRole?.coordinator === true} redirectTo="/admin/403">
              <MyDashboard />
            </ProtectedRoute>
          ),
        },
        { path: '403', element: <Page403 /> },
        {
          path: 'campaign',
          children: [
            { element: <Navigate to="/admin/campaign/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.campaign === true} redirectTo="/admin/403">
                  <CampaignList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.campaign === true} redirectTo="/admin/403">
                  <CampaignViewAdmin />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'ngo',
          children: [
            { element: <Navigate to="/admin/ngo/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.ngo === true} redirectTo="/admin/403">
                  <NGOList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.ngo === true} redirectTo="/admin/403">
                  <NgoView />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'donation',
          children: [
            { element: <Navigate to="/admin/donation/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.donor === true} redirectTo="/admin/403">
                  <DonationList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.donation === true} redirectTo="/admin/403">
                  <DonationDetails />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'donationdraft',
          children: [
            { element: <Navigate to="/admin/donationdraft/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.donation === true} redirectTo="/admin/403">
                  <DonationDraftList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.donation === true} redirectTo="/admin/403">
                  <DonationDraftDetails />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'userkyc',
          children: [
            { element: <Navigate to="/admin/userkyc/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/admin/403">
                  <UserKycList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/admin/403">
                  <UserKycDetails />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'ngolog',
          element: (
            <ProtectedRoute isAllowed={!!user && storedAccess?.campaign === true} redirectTo="/admin/403">
              <NgoLog />
            </ProtectedRoute>
          ),
        },
        {
          path: 'withdrawal',
          children: [
            { element: <Navigate to="/admin/withdrawal/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.withdrawal === true} redirectTo="/admin/403">
                  <WithdrawalList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.withdrawal === true} redirectTo="/admin/403">
                  <WithdrawalDetails />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'kyc',
          children: [
            { element: <Navigate to="/admin/kyc/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/admin/403">
                  <KycList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/admin/403">
                  <KycDetails />
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <Navigate to="/admin/users/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/admin/403">
                  <UsersList />
                </ProtectedRoute>
              ),
            },
            {
              path: ':id',
              element: (
                <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/admin/403">
                  <UsersView />
                </ProtectedRoute>
              ),
            },
          ],
        },
        // {
        //   path: 'enquiry',
        //   children: [
        //     { element: <Navigate to="/admin/enquiry/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: (
        //         <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/app">
        //           <EnquiryList />
        //         </ProtectedRoute>
        //       ),
        //     },
        //     {
        //       path: ':id',
        //       element: (
        //         <ProtectedRoute isAllowed={!!user && storedAccess?.user === true} redirectTo="/app">
        //           <EnquiryView />
        //         </ProtectedRoute>
        //       ),
        //     },
        //   ],
        // },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/admin/user/profile" replace />, index: true },
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'account', element: <UserAccount /> },
            { path: 'campaigns', element: <UserCampaigns /> },
            // { path: 'ngos', element: <UserNGOs /> },
            // { path: 'donations', element: <UserDonations /> },
          ],
        },
      ],
    },

    {
      path: '/',
      // element: <MainLayout />,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [
        { path: '*', element: <Page404 /> },
        { path: '/fundraisers/:title/:id/edit', element: <CampaignEdit /> },
        { path: '/fundraisers/:title/:id', element: <CampaignView /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const DonationExpenseReport = Loadable(lazy(() => import('../pages/dashboard/DonationExpenseReport')));
const MyDashboard = Loadable(lazy(() => import('../pages/dashboard/MyDashboard')));
const CampaignList = Loadable(lazy(() => import('../pages/campaign/CampaignList')));
const NGOList = Loadable(lazy(() => import('../pages/ngo/NGOList')));

// Donantion
const DonationList = Loadable(lazy(() => import('../pages/donation/DonationList')));
const DonationDetails = Loadable(lazy(() => import('../pages/donation/DonationDetails')));
const DonationDraftList = Loadable(lazy(() => import('../pages/donation/DonationDarftList')));
const DonationDraftDetails = Loadable(lazy(() => import('../pages/donation/DonationDraftDetails')));

// User KYC
const UserKycList = Loadable(lazy(() => import('../pages/userkyc/UserKycList')));
const UserKycDetails = Loadable(lazy(() => import('../pages/userkyc/UserKycDetails')));

// Ngo Log
const NgoLog = Loadable(lazy(() => import('../pages/dashboard/NgoLog')));

// Withdrawal
const WithdrawalList = Loadable(lazy(() => import('../pages/withdrawal/WithdrawalList')));
const WithdrawalDetails = Loadable(lazy(() => import('../pages/withdrawal/WithdrawalDetails')));

// KYC
const KycList = Loadable(lazy(() => import('../pages/kyc/KycList')));
const KycDetails = Loadable(lazy(() => import('../pages/kyc/KycDetails')));

// USERS
const UsersList = Loadable(lazy(() => import('../pages/users/UsersList')));
const UsersView = Loadable(lazy(() => import('../pages/users/UsersView')));

// Enquiry
const EnquiryList = Loadable(lazy(() => import('../pages/enquiry/EnquiryList')));
const EnquiryView = Loadable(lazy(() => import('../pages/enquiry/EnquiryView')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCampaigns = Loadable(lazy(() => import('../pages/dashboard/UserCampaigns')));
const UserNGOs = Loadable(lazy(() => import('../pages/dashboard/UserNGOs')));
const UserDonations = Loadable(lazy(() => import('../pages/dashboard/UserDonations')));

// TEST RENDER PAGE BY ROLE
const CampaignEdit = Loadable(lazy(() => import('../pages/campaign/CampaignEdit')));
const CampaignView = Loadable(lazy(() => import('../pages/campaign/CampiagnView')));
const CampaignViewAdmin = Loadable(lazy(() => import('../pages/campaign/admin/CampaignView')));
const NgoView = Loadable(lazy(() => import('../pages/ngo/NgoView')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
