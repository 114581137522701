import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fCurrency2(number) {
  return numeral(number).format('0,0');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fPercent2(number) {
  return numeral(number / 100).format('0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fAadharBreak(number) {
  // Check if the input contains any alphabet characters or spaces
  if (/[^0-9\s]/?.test(number)) {
    // If it contains alphabet characters or spaces, leave it as it is
    return number;
  }
  {
    // If it doesn't contain alphabet characters or spaces, format it
    const matches = number?.match(/\d{4}/g);
    const remainder = number?.slice((matches?.join('') || '')?.length);
    return `${matches?.join(' ')} ${remainder}`;
  }
}
