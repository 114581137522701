import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useAuth from '../../../hooks/useAuth';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import { IconButtonAnimate } from '../../../components/animate';
import { MenuPopover } from '../../../components/menu-popover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [];

const MENU_LOGIN = [
  {
    label: 'Login',
    linkTo: PATH_AUTH.login,
  },
  {
    label: 'Register',
    linkTo: PATH_AUTH.register,
  },
];

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const navigate = useNavigate();

  const { user, logout, isAuthenticated } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(null);

  const theme = useTheme();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const { copy } = useCopyToClipboard();

  const CopyText = `www.impaac.org?ref=${user?.ref}`;

  const onCopy = (shortUrl) => {
    if (shortUrl) {
      copy(shortUrl);
      enqueueSnackbar('Copied!');
    }
  };

  return (
    <>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <IconButtonAnimate onClick={handleOpen}>
          <MyAvatar />
        </IconButtonAnimate>
        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography
            variant="subtitle2"
            // noWrap
          >
            {user?.displayName}
          </Typography>
          {/* <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user?.role}
          </Typography> */}
        </Box>
      </RootStyle>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        {isAuthenticated && (
          <div>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email}
              </Typography>
            </Box>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
              Logout
            </MenuItem>
          </div>
        )}

        {!isAuthenticated && (
          <Stack sx={{ p: 1 }}>
            {MENU_LOGIN.map((option) => (
              <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                {option.label}
              </MenuItem>
            ))}
          </Stack>
        )}
      </MenuPopover>
    </>
  );
}
