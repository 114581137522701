import { useLocation } from 'react-router-dom';
// Analytics
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { StyledChart } from './components/chart';
import CircularLoader from './components/CircularLoader';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';


const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM
}

TagManager.initialize(tagManagerArgs)

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};


ReactPixel.init(process.env.REACT_APP_PIXEL_ID, options);
// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();
  
  // useEffect(() => {
  //   Ga()
  //   ReactPixel.pageView();
  // }, [location])

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <NotistackProvider>
          <CircularLoader />
          <ProgressBarStyle />
          <StyledChart />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
