import PropTypes from 'prop-types';
import { getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { useDropzone } from 'react-dropzone';
// @mui
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Image from '../Image';
import Iconify from '../Iconify';
import RejectionFiles from './RejectionFiles';
import uuidv4 from '../../utils/uuidv4';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: 250,
    height: 100,
    margin: 'auto',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    // borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
    },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadSingleFile2.propTypes = {
    error: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadSingleFile2({ error, file, placeholder, helperText, sx, ...other }) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        multiple: false,
        ...other,
    });

    return (
        <>
            <RootStyle
                sx={{
                    ...((isDragReject || error) && {
                        borderColor: 'error.light',
                    }),
                    ...sx,
                }}
            >
                <DropZoneStyle
                    {...getRootProps()}
                    sx={{
                        ...(isDragActive && { opacity: 0.72 }),
                    }}
                >
                    <input {...getInputProps()} />

                    {file && <Image alt="avatar" src={typeof file === 'string' ? file : file.preview} sx={{ zIndex: 8 }} />}

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            ...(file && {
                                opacity: 0,
                                color: 'common.white',
                                bgcolor: 'grey.900',
                                '&:hover': { opacity: 0.72 },
                            }),
                            ...((isDragReject || error) && {
                                bgcolor: 'error.lighter',
                            }),
                        }}
                    >
                        <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
                        <Typography variant="caption">{placeholder || 'Upload photo'}</Typography>
                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>

            {helperText && helperText}

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
        </>
    );
}


const storage = getStorage();


const UploadImage = (file) => {
    const storageRef = ref(storage, 'some-child');


    const uploadTask = uploadBytesResumable(storageRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            async () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL)
                });
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                // const imgURL = await uploadTask.snapshot.ref.getDownloadURL();
                // resolve(imgURL);
            }
        );
    })
}

// const UploadImage = async (croppedImage) => {
//     // setLoading(true)
//     const metadata = { contentType: 'image/jpeg' }
//     const storageRef = await firebase.storage().ref();
//     const uploadTask = storageRef
//         .child("campaignImage/" + uuidv4())
//         .put(croppedImage, metadata);

//     uploadTask.on(
//         firebase.storage.TaskEvent.STATE_CHANGED,
//         snapshot => {
//             var progress =
//                 (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

//             switch (snapshot.state) {
//                 case firebase.storage.TaskState.PAUSED:
//                     console.log("UPloading is paused");
//                     break;
//                 case firebase.storage.TaskState.RUNNING:
//                     console.log("UPloading is in progress...");
//                     break;
//             }
//             if (progress == 100) {
//                 console.log("uploaded", { type: "success" });
//             }
//         },
//         error => {
//             console.log("something is wrong in state change", { type: "error" });
//         },
//         () => {
//             uploadTask.snapshot.ref
//                 .getDownloadURL()
//                 .then(downloadURL => {

//                     localStorage.setItem("imageURL", JSON.stringify({
//                         imageURL: downloadURL
//                     }));
//                     // setImageURL(downloadURL)
//                     // setLoading(false)
//                 })
//                 .catch(err => console.log(err));
//         }
//     );
// }
